import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Content, Page } from '@backstage/core-components';
import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { HerokuLogotype } from './HerokuLogotype';
import LogoIcon from '../Root/LogoIcon';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#D1D7E5',
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, path, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<HerokuLogotype classes={{ svg, path }} />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'docs/default/component/backstage/plugins-creation',
                      label: 'Plugin Docs',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'docs/default/component/backstage/software-templates-creation',
                      label: 'Software Template Docs',
                      icon: <LogoIcon />,
                    },
                    {
                      url: 'https://devcenter.heroku.com/articles/platform-api-reference',
                      label: 'Platform API',
                      icon: <LogoIcon />,
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <InfoCard title="Composable Section">
                  <div style={{ height: 370 }} >
                    placeholder for content
                  </div>
                </InfoCard>
              </Grid> */}
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
