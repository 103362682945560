import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'ai-code-assist-center',
});

export const detailRouteRef = createRouteRef({
  id: 'ai-code-assist-details',
  params: ['assistantName'],
});
