import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { doraMetricsApiRef, DoraMetricsClient } from './dora.api';
import { CustomPagerDutyClient, customPDApiRef } from './pagerduty.api';

export const doraMetricsPlugin = createPlugin({
  id: 'dora-metrics',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: doraMetricsApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new DoraMetricsClient(discoveryApi, fetchApi),
    }),
    createApiFactory({
      api: customPDApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        config: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, config, fetchApi }) =>
        CustomPagerDutyClient.fromConfig(config, {
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const DoraMetricsPage = doraMetricsPlugin.provide(
  createRoutableExtension({
    name: 'DoraMetricsPage',
    component: () =>
      import('./components/DoraMetricsPage').then(m => m.DoraMetricsPage),
    mountPoint: rootRouteRef,
  }),
);

export const DoraMetricsTab = doraMetricsPlugin.provide(
  createRoutableExtension({
    name: 'DoraMetricsTab',
    component: () =>
      import('./components/DoraMetricsTab').then(m => m.DoraMetricsTab),
    mountPoint: rootRouteRef,
  }),
);
