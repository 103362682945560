import { DismissableBanner } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

const SeatedUserContentComponent = () => {
  const config = useApi(configApiRef);
  const startUrl = config.getString('aiCodeAssist.amazonQStartUrl');
  const awsRegion = config.getString(
    'aiCodeAssist.amazonQIdentityCenterRegion',
  );

  return (
    <div>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h2" component="h2">
            Configuring Amazon Q
          </Typography>
        </Grid>
        <Grid item>
          <DismissableBanner
            id="warning_banner"
            variant="warning"
            message="WARNING: Currently, Amazon Q uploads any file opened within a chat context to AWS.  As such, opening files containing secrets or other sensitive information should be avoided."
          />
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h3">
            Getting Help
          </Typography>
          <Typography variant="body1">
            Reach out in{' '}
            <Link href="https://salesforce.enterprise.slack.com/archives/C074P0MURBK">
              #heroku-amazon-q-setup
            </Link>{' '}
            for any help or questions.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h3">
            For JetBrains Users
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                Install the{' '}
                <Link
                  href="https://plugins.jetbrains.com/plugin/24267-amazon-q/"
                  target="_blank"
                >
                  Amazon Q IDE plugin
                </Link>{' '}
                in your JetBrains IDE.
              </li>
              <li>
                Choose the Amazon Q icon in your IDE. (The icon will be on the
                side of the interface by default.)
              </li>
              <li>
                Choose <strong>Use with Pro license</strong>.
              </li>
              <li>
                Fill in the <strong>Start URL</strong> with{' '}
                <strong>{startUrl}</strong>.
              </li>
              <li>
                Fill in the <strong>AWS Region</strong> with{' '}
                <strong>{awsRegion}</strong>
              </li>
              <li>
                Choose <strong>Continue</strong>. The focus will switch to your
                web browser.
              </li>
              <li>
                Follow the instructions in your browser to authenticate with IAM
                Identity Center.
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" component="h3">
            For Visual Studio Code Users
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                Install the{' '}
                <Link
                  href="https://marketplace.visualstudio.com/items?itemName=AmazonWebServices.amazon-q-vscode"
                  target="_blank"
                >
                  Amazon Q IDE extension
                </Link>{' '}
                in VS Code.
              </li>
              <li>
                Choose the Amazon Q icon in your IDE. (The icon will be on the
                side of the interface by default.)
              </li>
              <li>
                Choose <strong>Use with Pro license</strong>.
              </li>
              <li>
                Fill in the <strong>Start URL</strong> with{' '}
                <strong>{startUrl}</strong>.
              </li>
              <li>
                Fill in the <strong>AWS Region</strong> with{' '}
                <strong>{awsRegion}</strong>
              </li>
              <li>
                Choose <strong>Continue</strong>. The focus will switch to your
                web browser.
              </li>
              <li>
                Follow the instructions in your browser to authenticate with IAM
                Identity Center.
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4" component="h3">
            Amazon Q for CLI
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                <Link
                  href="https://desktop-release.codewhisperer.us-east-1.amazonaws.com/latest/Amazon%20Q.dmg"
                  target="_blank"
                >
                  Download Amazon Q for command line (macOS only)
                </Link>
              </li>
              <li>
                Authenticate with IAM Identity Center using the start URL:{' '}
                <strong>{startUrl}</strong>
              </li>
              <li>
                Follow the instructions to install the shell integrations, and
                to grant macOS accessiblity permissions.
              </li>
            </ol>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SeatedUserContentComponent;
