import React from 'react';
import {
  Content,
  Header,
  ItemCardGrid,
  ItemCardHeader,
  LinkButton,
  Page,
} from '@backstage/core-components';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { useRouteRef } from '@backstage/core-plugin-api';
import { detailRouteRef } from '../../routes';
import { Assistant, assistants } from '../../data';

const ChoiceCardComponent = ({
  title,
  description,
  assistantName,
}: Assistant) => {
  const getDetailsPath = useRouteRef(detailRouteRef);
  const path = getDetailsPath({ assistantName });

  return (
    <Card>
      <CardMedia>
        <ItemCardHeader title={title} />
      </CardMedia>
      <CardContent>{description}</CardContent>
      <CardActions>
        {path === undefined || path === null ? (
          ''
        ) : (
          <LinkButton color="primary" to={path}>
            Go &raquo;
          </LinkButton>
        )}
      </CardActions>
    </Card>
  );
};

export const AssistantChoicesComponent = () => {
  return (
    <Page themeId="tool">
      <Header
        title="AI Code Assistants"
        subtitle="Manage your access to Salesforce approved AI code assistants."
      />

      <Content>
        <ItemCardGrid>
          {assistants.map((assistant, i) => (
            <ChoiceCardComponent key={i} {...assistant} />
          ))}
        </ItemCardGrid>
      </Content>
    </Page>
  );
};
