import React, { useEffect, useState } from 'react';
import { InfoCard } from '@backstage/core-components';
import InstructionalContentComponent from './InstructionalContentComponent';
import { useIamHubMembershipApi } from '../../hooks/useIamHub';
import { ProfileProps } from '../../data/types';
import { Button, Grid, Typography } from '@material-ui/core';

type SeatActionProps = {
  action: () => void;
};

const SeatReleaseComponent = ({ action }: SeatActionProps) => {
  return (
    <div>
      <Typography variant="h4" component="h3">
        Not using Amazon Q?
      </Typography>
      <p>
        Release your seat. You can alway request a new seat, right here, if you
        miss using Amazon Q.
      </p>
      <div>
        <Button color="secondary" onClick={action}>
          Please release my seat.
        </Button>
      </div>
    </div>
  );
};

const SeatRequestComponent = ({ action }: SeatActionProps) => {
  return (
    <div>
      <Typography variant="h4" component="h3">
        Looks like you don't have an Amazon Q seat.
      </Typography>
      <p>You can request a seat, right here!</p>
      <div>
        <Button color="primary" onClick={action}>
          Please grant me a seat.
        </Button>
      </div>
    </div>
  );
};

type SeatManagementComponentProps = {
  email: string;
};

const SeatManagementComponent = ({ email }: SeatManagementComponentProps) => {
  const [hasSeat, setHasSeat] = useState(false);
  const membershipApi = useIamHubMembershipApi({
    endpointSlug: 'amazon-q',
    email,
  });

  useEffect(() => {
    const doRequest = async () => {
      const { is_member } = await membershipApi.get();
      setHasSeat(is_member);
    };

    doRequest();
  }, [membershipApi]);

  async function releaseSeat() {
    const { is_member } = await membershipApi.destroy();
    setHasSeat(is_member);
  }

  async function requestSeat() {
    const { is_member } = await membershipApi.create();
    setHasSeat(is_member);
  }

  return (
    <InfoCard>
      {hasSeat ? (
        <SeatReleaseComponent action={releaseSeat} />
      ) : (
        <SeatRequestComponent action={requestSeat} />
      )}
    </InfoCard>
  );
};

export const AwsQDetailsComponent = ({ profile }: ProfileProps) => {
  const email = profile?.email;

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <InfoCard>
          <InstructionalContentComponent />
        </InfoCard>
      </Grid>

      {email && (
        <Grid item xs={4}>
          <SeatManagementComponent email={email} />
        </Grid>
      )}
    </Grid>
  );
};
