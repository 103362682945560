import { AwsQDetailsComponent } from '../components/AwsQDetailsComponent';
import { CodeGenieDetailsComponent } from '../components/CodeGenieDetailsComponent';

import { Assistant, AssistantIndex } from './types';

export const assistants: Array<Assistant> = [
  {
    title: 'Amazon Q',
    description:
      'Request or release your Amazon Q seat, or read installation instructions.',
    assistantName: 'amazon-q',
    component: AwsQDetailsComponent,
  },
  {
    title: 'Github Copilot',
    description: 'Coming soon.',
    assistantName: 'github-copilot',
  },
  {
    title: 'Salesforce CodeGenie',
    description:
      'Learn how to install Salesforce CodeGenie into VS Code or IntelliJ.',
    assistantName: 'salesforce-codegenie',
    component: CodeGenieDetailsComponent,
  },
];

const indexOnName = (index: AssistantIndex, assistant: Assistant) => {
  const { assistantName } = assistant;
  index[assistantName] = assistant;
  return index;
};

export const assistantsByName: AssistantIndex =
  assistants.reduce<AssistantIndex>(indexOnName, {});
