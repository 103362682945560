import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const Analytics = ({
  children,
}: {
  children: React.JSX.Element[] | React.JSX.Element;
}) => {
  const discoveryApi = useApi(discoveryApiRef);
  const location = useLocation();

  discoveryApi.getBaseUrl('analytics').then(value => {
    const url = `${value}/count`;
    const data = {
      name: 'backstage.pages.view',
      attributes: { action: location.pathname },
    };
    fetch(url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      // eslint-disable-next-line no-console
    }).then(response => console.log(response));
  });

  return children;
};
