import { createApiRef, FetchApi } from '@backstage/core-plugin-api';

export interface RequestQuery {
  status?: string;
  target_name?: string;
  interval: string;
  component?: string;
}

export interface ResultLeadTimeQuery {
  period: string;
  avg_lead_time_hours: string;
  count: string;
  status: string;
  target_name: string;
  component: string;
}

export interface ResultDeploymentFrequencyQuery {
  period: string;
  count: string;
  status: string;
  target_name: string;
  component: string;
}

export interface DoraMetricsApi {
  deploymentFrequency: (
    data: RequestQuery,
  ) => Promise<ResultDeploymentFrequencyQuery[]>;
  leadTime: (data: RequestQuery) => Promise<ResultLeadTimeQuery[]>;
}

export const doraMetricsApiRef = createApiRef<DoraMetricsApi>({
  id: 'plugin.dora-metrics.service',
});

import { DiscoveryApi } from '@backstage/core-plugin-api';

export class DoraMetricsClient implements DoraMetricsApi {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async leadTime(query: RequestQuery): Promise<ResultLeadTimeQuery[]> {
    const metricsUrl = await this.doraMetricsUrl();
    const url = `${metricsUrl}/lead_time`;
    this.cleanEmpty(query as any);
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    const data = await response.json();
    return data.map((item: ResultLeadTimeQuery) => ({
      period: new Date(item.period).toDateString(),
      avg_lead_time_hours: item.avg_lead_time_hours,
      count: item.count,
      status: item.status,
      target_name: item.target_name,
      component: item.component,
    }));
  }

  async deploymentFrequency(
    query: RequestQuery,
  ): Promise<ResultDeploymentFrequencyQuery[]> {
    const metricsUrl = await this.doraMetricsUrl();
    const url = `${metricsUrl}/deployment_frequency`;
    this.cleanEmpty(query as any);
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });

    const data = await response.json();
    return data.map((item: ResultLeadTimeQuery) => ({
      period: new Date(item.period).toDateString(),
      avg_lead_time_hours: item.avg_lead_time_hours,
      count: item.count,
      status: item.status,
      target_name: item.target_name,
      component: item.component,
    }));
  }

  private async doraMetricsUrl(): Promise<string> {
    return `${await this.discoveryApi.getBaseUrl('dora-metrics')}`;
  }

  private cleanEmpty(obj: { [x: string]: string }) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === 'EMPTY') {
        obj[key] = '';
      }
    });
  }
}
