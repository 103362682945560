import {
  ApiRef,
  BackstageIdentityApi,
  createApiRef,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { SignInProviderConfig } from '@backstage/core-components';

export type hkidOAuthApi = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi;

/**
 * A reference to the HK-ID OAuth API.
 */
export const hkidOAuthApiRef: ApiRef<hkidOAuthApi> = createApiRef({
  id: 'auth.hkid.oauth',
});

/**
 * Configuration for the HK-ID OAuth provider.
 */
export const hkidOAuthProvider: SignInProviderConfig = {
  id: 'oauth2',
  title: 'Sign In',
  message: 'Sign in using your Salesforce SSO login',
  apiRef: hkidOAuthApiRef,
};
