import { createApiRef, FetchApi } from '@backstage/core-plugin-api';

export interface Query {
  calculations: Visualize[];
  filters: Filter[];
  granularity?: number;
  breakdowns?: [];
  orders?: [];
  havings?: [];
  filter_combination?: string;
  limit: number;
  time_range: number;
  start_time?: number;
  end_time?: number;
}

export interface Visualize {
  op: string;
  column?: string;
}

export interface Filter {
  op: string;
  column?: string;
  value?: string;
}

export interface QueryCreationResponse {
  id: string;
}

export interface QueryResults {
  links: LinksState;
}

interface LinksState {
  query_url: string;
  graph_image_url: string;
}

export interface HoneycombApi {
  getQueryResults: (query: Query) => Promise<QueryResults>;
  isQuery: (obj: any) => obj is Query;
  loadQueries: (jsonUrl: string | null) => Promise<Query[]>;
  parseQueries: (jsonString: string | null) => Query[] | null;
}

export const honeycombApiRef = createApiRef<HoneycombApi>({
  id: 'plugin.honeycomb-dashboard.service',
});

import { DiscoveryApi } from '@backstage/core-plugin-api';

export class HoneycombApiClient implements HoneycombApi {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;

  constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async createQuery(query: Query): Promise<QueryCreationResponse> {
    const url = await this.honeycombUrl();
    return await this.fetch<QueryCreationResponse>(`/queries/__all__`, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
  }

  async getQueryResults(query: Query): Promise<QueryResults> {
    const qidResponse = await this.createQuery(query);
    const url = await this.honeycombUrl();
    return await this.fetch<QueryResults>(`/query_results/__all__`, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query_id: qidResponse.id }),
    });
  }

  async loadQueries(jsonString: string | null): Promise<Query[]> {
    const url = `${await this.honeycombBackendUrl()}/github_content/${jsonString}`;
    const apiResponse = await this.fetchApi.fetch(url);
    const { data } = await apiResponse.json();
    try {
      if (data === null) return [];
      const obj: Query[] = data;
      return obj.filter(value => this.isQuery(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing JSON:', error);
      throw new Error('Could not parse JSON');
    }
  }

  isQuery(obj: any): obj is Query {
    return (
      obj !== null &&
      typeof obj === 'object' &&
      'calculations' in obj &&
      'time_range' in obj
    );
  }

  parseQueries(jsonString: string | null): Query[] | null {
    try {
      if (jsonString === null) return null;
      const obj: Query[] = JSON.parse(jsonString);
      return obj.filter(value => this.isQuery(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  private async honeycombUrl(): Promise<string> {
    return `${await this.discoveryApi.getBaseUrl('proxy')}/honeycomb-api`;
  }

  private async honeycombBackendUrl(): Promise<string> {
    return this.discoveryApi.getBaseUrl('honeycomb-dashboard');
  }

  private async fetch<T = any>(
    input: string,
    url: string,
    init?: RequestInit,
  ): Promise<T> {
    const resp = await this.fetchApi.fetch(`${url}${input}`, init);
    if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText} ${url}`);
    return await resp.json();
  }
}
