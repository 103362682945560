import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { AssistantChoicesComponent } from './components/AssistantChoicesComponent';
import { AssistantDetailComponent } from './components/AssistantDetailComponent';

import { rootRouteRef, detailRouteRef } from './routes';

export const aiCodeAssistCenterPlugin = createPlugin({
  id: 'ai-code-assist-center',
  routes: {
    root: rootRouteRef,
    details: detailRouteRef,
  },
});

export const AiCodeAssistCenterPage = aiCodeAssistCenterPlugin.provide(
  createRoutableExtension({
    name: 'AiCodeAssistCenterPage',
    component: () => Promise.resolve(AssistantChoicesComponent),
    mountPoint: rootRouteRef,
  }),
);

export const AiCodeAssistDetailPage = aiCodeAssistCenterPlugin.provide(
  createRoutableExtension({
    name: 'AiCodeAssistDetailPage',
    component: () => Promise.resolve(AssistantDetailComponent),
    mountPoint: detailRouteRef,
  }),
);
