import { InfoCard } from '@backstage/core-components';
import { Grid, Link, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';

type InlineCodeProps = {
  children: ReactNode;
};

const inlineCodeStyle = { backgroundColor: '#f6f6f6', padding: '.25rem' };

const InlineCode = ({ children }: InlineCodeProps) => (
  <small>
    <strong>
      <code style={inlineCodeStyle}>{children}</code>
    </strong>
  </small>
);

export const CodeGenieDetailsComponent = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <InfoCard>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h2" component="h2">
                Configuring Salesforce CodeGenie
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" component="h3">
                Installing the CodeGenie plugin to local IntelliJ v2022.2+
              </Typography>
              <Typography variant="body1">
                To install CodeGenie into a local instance of IntelliJ, you must
                first cofigure the Nexus Plugin Repository.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Configuring the Nexus Plugin Repository
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>Connect to ZScaler or Cisco VPN.</li>
                  <li>
                    Open <strong>Settings</strong> and select{' '}
                    <strong>Plugins</strong>.
                  </li>
                  <li>
                    In the <strong>Plugins</strong> settings menu (gear symbol),
                    select <strong>Manage Plugin Repositories...</strong>
                  </li>
                  <li>
                    Add&nbsp;
                    <InlineCode>
                      https://nexus-proxy.repo.local.sfdc.net/nexus/repository/eclipse-p2/intellij/plugins/updatePlugins.xml
                    </InlineCode>
                    &nbsp; as a repository.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Installing the CodeGenie plugin from Disk
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>
                    <Link href="https://git.soma.salesforce.com/intellij/CodeGenie/archive/refs/tags/6.2.2.zip">
                      Download the plugin.
                    </Link>
                  </li>
                  <li>
                    In IntelliJ press <strong>Ctrl Alt 0S</strong> to open
                    settings.
                  </li>
                  <li>
                    From the <strong>IntellIJ IDEA/settings</strong> menu,
                    select <strong>Plugins</strong>.
                  </li>
                  <li>
                    On the Plugins page, click the gear symbol, then click{' '}
                    <strong>Install Plugin from Disk</strong>.
                  </li>
                  <li>
                    Select the plugin archive file and click <strong>OK</strong>
                    .
                  </li>
                  <li>
                    Click <strong>OK</strong> to apply the changes. Restart the
                    IDE if prompted.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Installing the CodeGenie plugin from Marketplace
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>
                    Open the <strong>Settings</strong> and select{' '}
                    <strong>Plugins</strong>.
                  </li>
                  <li>
                    Select <strong>Marketplace</strong>.
                  </li>
                  <li>
                    Search for <strong>CodeGenie</strong> in the search bar. If
                    CodeGenie is not found, be sure the{' '}
                    <strong>Nexus Plugin Repository</strong> is correctly
                    configured.&nbsp;
                    <i>
                      See above for instructions on configuring the Nexus Plugin
                      Repository.
                    </i>
                  </li>
                  <li>Install the CodeGenie plugin.</li>
                  <li>
                    If you have either the “Machine Learning Code Completion” or
                    “Recommenders for Java and Python” plugins, disable them for
                    optimal use.
                  </li>
                  <li>
                    Restart IntelliJ to make sure the installation is in effect.
                  </li>
                  <li>
                    Follow the prompt within the IDE to{' '}
                    <strong>authenticate via QuantumK</strong> for the first
                    time.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Verifying Installation
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>Connect to ZScaler or Cisco VPN.</li>
                  <li>
                    Open an existing file. It must be one of the{' '}
                    <Link href="https://salesforce.quip.com/ItJ4AfjMYaXF#temp:C:dVC79311c3a26e543689f9652b26">
                      CodeGenie supported file types
                    </Link>
                    .
                  </li>
                  <li>
                    Within IntelliJ, edit some code. After a few seconds you
                    should see some generated code.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" component="h3">
                Installing the CodeGenie plugin to local VSCode v1.69.0+
              </Typography>
              <Typography variant="body1">
                The CodeGenie plugin can be installed from a VSIX file or from
                the Internal Marketplace by following the instructions below.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Installing from a VSIX file
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>
                    Install VSCode v1.69.0 or newer on your local machine.
                  </li>
                  <li>
                    <Link href="https://sfdc.co/get-code-genie-vsc">
                      Download the latest version of the CodeGenie extension.
                    </Link>
                  </li>
                  <li>
                    Open the <strong>Extensions</strong> view.
                  </li>
                  <li>
                    In the top-right of the <strong>Extensions</strong> pane,
                    click the <InlineCode>...</InlineCode> button, and choose{' '}
                    <strong>Install from VSIX...</strong>
                  </li>
                  <li>
                    Navigate to the directory containing the VSIX file, select
                    and open it.
                  </li>
                  <li>
                    Follow the prompt within the IDE to{' '}
                    <strong>authenticate via QuantumK</strong> for the first
                    time.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Installing from the Internal Marketplace
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>
                    Run{' '}
                    <InlineCode>
                      bootstrap _install:vscode-extensions
                    </InlineCode>
                    . On Mac OSX, if the bootstrap command is unavailable after
                    installing Python, run the following command:{' '}
                    <InlineCode>
                      python3 &lt;(curl
                      https://sfdc-ansible.s3.amazonaws.com/honu-apps/installall)
                    </InlineCode>
                  </li>
                  <li>
                    Add (or edit) the <InlineCode>~/.netrc</InlineCode> file on
                    your machine to contain the following code:
                    <pre style={inlineCodeStyle}>
                      <InlineCode>
                        machine git.soma.salesforce.com
                        <br />
                        &nbsp;&nbsp;login &lt;git-soma-token&gt;
                        <br />
                        &nbsp;&nbsp;password &lt;git-soma-token&gt;
                      </InlineCode>
                    </pre>
                  </li>
                  <li>Install CodeGenie from the marketplace.</li>
                  <li>
                    Go to Command Palette (<InlineCode>cmd+shift+P</InlineCode>)
                    and run{' '}
                    <strong>Internal Marketplace: Install Extensions</strong>.
                  </li>
                  <li>
                    Select <strong>CodeGenie</strong> and Click{' '}
                    <strong>OK</strong>.
                  </li>
                  <li>
                    Follow the prompt within the IDE to{' '}
                    <strong>authenticate via QuantumK</strong> for the first
                    time.
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h4">
                Verifying Installation
              </Typography>
              <Typography variant="body1">
                <ol>
                  <li>Connect to ZScaler or Cisco VPN.</li>
                  <li>
                    Open an existing file. It must be one of the{' '}
                    <Link href="https://salesforce.quip.com/ItJ4AfjMYaXF#temp:C:dVC79311c3a26e543689f9652b26">
                      CodeGenie supported file types
                    </Link>
                    .
                  </li>
                  <li>
                    Within VSCode, edit some code. After a few seconds you
                    should see some generated code.
                  </li>
                </ol>
              </Typography>
            </Grid>
          </Grid>
        </InfoCard>
      </Grid>
    </Grid>
  );
};
